<template>
  <section class="my-5">
    <div class="row pl-3 px-md-5">
      <div class="col">
        <h2>Recommendation For You</h2>
      </div>
    </div>
    <div class="row">
      <div class="col slider-product">
        <PuSkeleton width="100%" height="230px" v-if="isLoading" />
        <VueSlickCarousel
          v-bind="slickSettings"
          class="slider-product-box m-0 w-100"
          v-if="products.length"
        >
          <ProductBox
            :product="product"
            v-for="(product, index) in products"
            :key="index"
          />
        </VueSlickCarousel>
      </div>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import ProductBox from "@/components/mains/ProductBox.vue";

export default {
  name: "RecommendedProducts",
  components: {
    VueSlickCarousel,
    ProductBox,
  },
  data() {
    return {
      products: [],
      slickSettings: {
        // centerMode: true,
        // centerPadding: "20px",
        // slidesToShow: 5,
        slidesToShow: 5,
        slidesToScroll: 5,
        arrows: true,
        swipeToSlide: true,
        // lazyLoad: "ondemand",
        responsive: [
          {
            breakpoint: 769,
            settings: {
              arrows: false,
              // centerMode: false,
              // slidesToShow: 3,
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 481,
            settings: {
              arrows: false,
              // centerMode: false,
              // slidesToShow: 2,
              rows: 2,
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
        ],
      },
      isLoading: true,
    };
  },
  watch: {
    "$store.getters.getResellerData"(resellerData) {
      if (resellerData.status_verify) this.getRecommendedProducts();
    },
  },
  mounted() {
    this.getRecommendedProducts();
  },
  methods: {
    async getRecommendedProducts() {
      this.isLoading = true;
      let keyword = this.$route.params.id.split("-");
      keyword = Number.isNaN(parseInt(keyword[keyword.length - 1])) ? keyword.join(" ") : keyword.slice(0, -1).join(" ");
      try {
        const filter = {
          filters: [{
              property: "name",
              operator: "like",
              values: [{ value: keyword }]
          }]
        };
        const res = await this.$api.product.getProductList(0, 10, undefined, filter)
        if (res.status === 200) this.setProducts(res.data);
        else this.$helpers.toaster.make(res.data.message, "danger")
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.error(error);
      }
    },
    setProducts(data) {
      this.products = data["data"]["result"];
    },
  },
};
</script>

